// 实名认证
import service from '../request/index'
import qs from 'qs'

const baseUrl = window.globalUrl.HOME_API

// 获取短信验证码
export const getSmsCode = (params) => {
  return service({
    url: `${baseUrl}/admin/mobile/sendsms/${params}`,
    method: 'get',
  })
}
// 获取短信验证码-新
//用户注册1,用户登录2,密码修改3,实名认证4,企业认证5,联系人6
export const getSmsCodeNew = (params) => {
  return service({
    url: `${baseUrl}/admin/vcc/sendSms`,
    method: 'get',
    params: params
  })
}

// 个人认证
export const certificateByCard = (params) => {
  return service({
    url: `${baseUrl}/admin/userauth/setauthinfo`,
    method: 'post',
    data: params
  })
}

// 企业认证
export const certificateByLisence = (params) => {
  return service({
    url: `${baseUrl}/admin/companyauth/setauthinfo`,
    method: 'post',
    data: params
  })
}

//农户认证
export const farmerCertificate = (params) => {
  return service({
    url: `${baseUrl}/admin/farmer/authUser`,
    method: 'post',
    data: qs.stringify(params),
  })
}

//集体组织认证
export const groupOrgCertificate = (params) => {
  return service({
    url: `${baseUrl}/admin/group/authGroup`,
    method: 'post',
    data: qs.stringify(params),
  })
}

//新型经营主体
export const authCompany = (params) => {
  return service({
    url: `${baseUrl}/admin/company/authCompany`,
    method: 'post',
    data: qs.stringify(params),
  })
}

//查询行政区树
export const getAreaTree = (params) => {
  return service({
    url: `${baseUrl}/admin/area/areaTree`,
    method: 'get',
    params: params
  })
}


// 营业执照OCR认证
export const getyyzz = (params) => {
  return service({
    url: `${baseUrl}/admin/identity/saveBusinessLicense`,
    method: 'post',
    params: params
  })
}
// 身份证OCR认证
export const getsfz = (params) => {
  return service({
    url: `${baseUrl}/admin/identity/saveIdentity`,
    method: 'post',
    params: params
  })
}


